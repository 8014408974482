// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-mdx": () => import("./../../../src/pages/about.mdx" /* webpackChunkName: "component---src-pages-about-mdx" */),
  "component---src-pages-privacy-policy-mdx": () => import("./../../../src/pages/privacy-policy.mdx" /* webpackChunkName: "component---src-pages-privacy-policy-mdx" */),
  "component---theme-src-pages-404-js": () => import("./../../../../theme/src/pages/404.js" /* webpackChunkName: "component---theme-src-pages-404-js" */),
  "component---theme-src-templates-recipe-js": () => import("./../../../../theme/src/templates/recipe.js" /* webpackChunkName: "component---theme-src-templates-recipe-js" */),
  "component---theme-src-templates-recipes-js": () => import("./../../../../theme/src/templates/recipes.js" /* webpackChunkName: "component---theme-src-templates-recipes-js" */),
  "component---theme-src-templates-tagged-recipes-js": () => import("./../../../../theme/src/templates/taggedRecipes.js" /* webpackChunkName: "component---theme-src-templates-tagged-recipes-js" */)
}

