module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-162797845-1","head":true,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/vercel/path0/theme/src/components/MarkdownPagesLayout.js","pages":"/vercel/path0/theme/src/components/MarkdownLayout.js"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":510,"maxHeight":510,"linkImagesToOriginal":false,"withWebp":true,"quality":75}}],"extensions":[".mdx"],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/vercel/path0/site"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Krissi Cooks","short_name":"Krissi Cooks","start_url":"/","background_color":"#FFF","theme_color":"#FFF","display":"standalone","icon":"/vercel/path0/theme/src/images/favicon.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"5ba6f3df7db6e689317d05b8a24925c5"},
    },{
      plugin: require('../../theme/gatsby-browser.js'),
      options: {"plugins":[],"title":"Krissi Cooks","shortTitle":"Krissi Cooks"},
    }]
