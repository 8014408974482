import theme from "@tuesd4y/gatsby-recipe-blog/src/gatsby-plugin-theme-ui"

export default {
  ...theme,
  colors: {
    ...theme.colors,
    primary: "#EE806D",
    background: "#ededed",
    muted: "#F3C0AC",
    modes: {
      dark: {
        ...theme.colors.modes.dark,
        muted: "#472438"
      }
    }
  }
};
